<template>
  <div class="card p-4" style="width: 300px">
    <div>
      <figure class="image">
        <img :src="data.category.imagen" :alt="data.category.description" />
      </figure>
    </div>
    <div>
      <div>
        <h1 class="h1 has-text-primary-text mt-3">{{data.name}}</h1>
        <div class="is-divider m-3"></div>
        <h4 class="has-text-primary-text my-1">
          {{ data.initialHour | dateFormat }} / {{ data.finalHour | dateFormat
          }}
        </h4>
        <h4 class="has-text-primary-text my-1">Aforo {{ data.capacity }} usuarios</h4>
        <h4 class="has-text-primary-text my-1">
          <span class="has-text-primary-text">Moderador</span>
        </h4>
        <h4 class="has-text-primary-text has-text-weight-light my-1">
          - {{ data.moderator.name }} {{ data.moderator.lastName }}
        </h4>
        <div class="is-divider m-3"></div>
        <h4 class="has-text-primary-text my-3">Descripción</h4>
        <h4 class="has-text-primary-text has-text-weight-light">{{ data.description }}</h4>
        <button
          v-if="data.videoUrl"
          class="button is-green is-fullwidth mt-3"
        >
          <a :href="data.videoUrl" target="_blank">Ver video</a>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const {DateTime} = require("luxon");

export default {
  name: "VideoModal",
  props:{
    data: {
      required: true
    }
  },
  filters:{
    dateFormat: function (value){
      return DateTime.fromISO(value).toFormat('dd LLLL yyyy ', { locale: "es" })
    }
  }
}
</script>

<style scoped>

</style>
