<template src="./ViewAllRecomendedCourses.html"></template>

<script>
import OfferThumbnail from "@/components/OfferThumbnail/OfferThumbnail";
import EmployerOfferThumbnail from "@/components/EmployerOfferThumbnail/EmployerOfferThumbnail";
import ArrobaMedellinHorizontalCard from "@/components/ArrobaMedellinHorizontalCard/ArrobaMedellinHorizontalCard";
import ArrobaMedellinVerticalCardEvents from "@/components/ArrobaMedellinVerticalCardEvents/ArrobaMedellinVerticalCardEvents";
import createEditEvent from "@/components/Events/createEditEvent";
import showEventDetails from "@/components/Events/showEventDetails";
import { mapGetters } from "vuex";
import eventsService from "@/services/Events";
import coursesService from "@/services/Courses";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "ViewAllRecomendedCourses",
  components: {
    OfferThumbnail,
    EmployerOfferThumbnail,
    ArrobaMedellinHorizontalCard,
    ArrobaMedellinVerticalCardEvents,
    createEditEvent,
    showEventDetails,
    ArrobaMedellinArrobitaNoData,
  },
  data() {
    return {
      typeCard: { button: `button--${this.classCard}`, progress: "is-info" },
      columnWidth: 6,
      isComponentModalActive: false,
      dataModalComponent: {},
      modalComponent: "",
      title: "Ver todos los cursos recomendados",
      component: "ArrobaMedellinHorizontalCard",
      classCard: "purple",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sapiencia-ciudadela-test.appspot.com/o/background%2Fbackground-academico.png?alt=media&token=0b6a920a-f253-4234-ac42-b3dafac1ffbe",
      data: [],
      recommendedMedellin: true,
    };
  },
  created() {
    this.getRecommendedCourses();
  },
  mounted() {
    this.$refs.background.style.background =
      "url(" + this.background + ") center no-repeat fixed";
    if (this.component === "ArrobaMedellinVerticalCardEvents")
      this.columnWidth = 4;
  },
  methods: {
    showEventDetails(data) {
      if (this.user.tipo_rol === "Administrator") {
        this.isComponentModalActive = true;
        this.dataModalComponent = { ...data };
        this.modalComponent = "showEventDetails";
      }
    },
    editEvent(eventData) {
      if (this.user.tipo_rol === "Administrator") {
        this.isComponentModalActive = true;
        this.dataModalComponent = { ...eventData };
        this.modalComponent = "createEditEvent";
      }
    },
    async deleteEvent(eventData) {
      this.$buefy.dialog.confirm({
        title: "Eliminar evento",
        type: "is-green",
        message: "¿Estás seguro que quieres eliminar esta evento?",
        confirmText: "Eliminar evento",
        cancelText: "Cancelar",
        onConfirm: async () => {
          try {
            await eventsService.deleteEvent(eventData.id);
            if (this.$route.name !== "Events")
              this.$router.push({ name: "Events" });
            this.$buefy.toast.open({
              duration: 5000,
              message: "Evento eliminado exitosamente",
              position: "is-bottom",
              type: "is-success",
            });
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: "is-bottom",
              type: "is-danger",
            });
          }
        },
      });
    },
    async getRecommendedCourses() {
      try {
        this.data = await coursesService.getAllCoursesRecommendations(
          "@Medellin"
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getAllCoursesRecommendations(type) {
      try {
        if (type === "Universidad") {
          this.recommendedMedellin = false;
        } else {
          this.recommendedMedellin = true;
        }
        this.data = await coursesService.getAllCoursesRecommendations(type);
      } catch (e) {
        console.log("e", e);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped lang="scss" src="./ViewAllRecomendedCourses.scss">
</style>
