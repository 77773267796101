<template src="./ArrobaMedellinHorizontalCard.html"></template>

<script>
export default {
  name: 'arroba-medellin-horizontal-card',
  props: {
    img: {type: String},
    imgDescription: { type: String, default: "Imagen" },
    title: {type: String},
    subtitle: {type: [String, Object]},
    body: {type: String, required: false},
    typeCard: {type: Object},
    buttonText: {type: String},
    url: {type: String, default: ""},
    showImage: {type: Boolean, default: true}
  },
  methods: {
    pushButton() {
      this.$emit('horizontalCardClickButton');
      this.openCourseUrl(this.url);
    },
    openCourseUrl(url) {
      if (url.length) {
        window.open(url, '_blank');
         this.$router.go(0)
      }
    }
  },
  computed: {
    disableButton() {
      return this.url.length < 1;
    }
  }
}
</script>

<style lang="scss" scoped src="./ArrobaMedellinHorizontalCard.scss"></style>
