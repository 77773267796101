<template src="./ArrobaMedellinVerticalCardEvents.html"></template>

<script>
import { mapGetters } from "vuex";
import costumeDialog from "@/components/CostumeDialog/CostumeDialog";
import VideoModal from "@/components/VideoModal/VideoModal";
const { DateTime } = require("luxon");
import { roleName } from "@/utils/constants";

export default {
  props: {
    data: { type: Object },
    horizontal: {
      type: Boolean,
      default: false,
      required: false,
    },
    justView: {
      type: Boolean,
      default: false,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
      required: false,
    },
    eventClosed: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  name: "arroba-medellin-vertical-card-events",
  components: {
    costumeDialog,
    VideoModal,
  },
  data() {
    return {
      buttonText: null,
      disableButton: null,
      buttonDialog: "",
      isCardModalActive: false,
      isCardModalActive1: true,
      role: null,
      roleName,
      isComponentModalActive: false,
      formProps: {
        email: "evan@you.com",
        password: "testing",
      },
    };
  },
  created() {
    this.role = this.user.role.name;
    this.definedText();
  },
  methods: {
    cardModal() {
      this.$buefy.modal.open({
        parent: this,
        component: VideoModal,
        props: {
          data: this.data,
        },
        hasModalCard: true,
        customClass: "custom-class",
        trapFocus: true,
      });
    },
    async pushButton() {
      this.$emit("showEventDetails", this.data);
      if (this.role !== this.roleName.ADMINISTRATOR) {
        if (!this.data.isRegistered) {
          await (this.buttonDialog = "Separa tu cupo");
          this.$refs.dialog.showDialog(this.data, this.buttonDialog);
        } else if (
          this.data.isFullCapacity
        ) {
          await (this.buttonDialog = "Aforo lleno");
          this.disableButton = true;
        }
        else if (this.eventClosed || new Date(this.data.finalHour) - new Date() < 0) {
          this.$store.dispatch("fetchWatchAndRate", {
            show: true,
            data: this.data,
          });
        } else if (
          this.data.isRegistered &&
          !(new Date(this.data.initialHour) - new Date() > 0) &&
          !(new Date(this.data.finalHour) - new Date() < 0)
        ) {
          this.$store.dispatch("fetchShareAndLoginEvent", {
            show: true,
            data: this.data,
          });
        } else {
          this.$refs.dialog.showDialog(this.data, "Ya tienes un cupo separado");
        }
      }
    },
    definedText() {
      if (
        this.data.isRegistered ||
        this.data.isAvailable ||
        this.user.role.name === "Administrator" ||
        !(new Date(this.data.initialHour) - new Date() > 0)
      ) {
        this.buttonText = "Ver evento";
        this.disableButton = false;
      }

      if (this.data.isFullCapacity) {
        this.buttonText = "Aforo lleno";
        this.disableButton = true;
      }
      /*      if(this.aforo === this.person) {
              this.buttonText = 'Sala llena'
              this.disableButton = true
            } else if (this.person > 0) {
              this.buttonText = 'Ingresar'
              this.disableButton = false
            }*/
      if (this.eventClosed) {
        this.buttonText = "Ver información";
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  filters: {
    dateFormat: function (value) {
      return DateTime.fromISO(value).toUTC().toFormat("HH:mm - dd LLLL yyyy", {
        locale: "es",
      });
    },
  },
};
</script>

<style lang="scss" scoped src="./ArrobaMedellinVerticalCardEvents.scss"></style>
