<script>
/* eslint-disable */
import eventsService from "@/services/Events";
import historyService from "@/services/History";

export default {
  name: "costumeDialog",
  render: function (createElement) {
    return null;
  },
  props: ["data", "button"],
  methods: {
    // Accion para separar cupo o ingresar al evento
    showDialog(data, button, cancelButton) {
      this.$buefy.dialog.confirm({
        title: data.name,
        message: this.$slots.default,
        cancelText: "Cancelar",
        confirmText: button,
        type: "is-green",
        onConfirm: async () => {
          try {
            if (button == "Separa tu cupo") {
              await eventsService.registerForAnEvent(data.id);
              const history = {
                title: `${data.name}`,
                description: `Has separado un cupo en el evento ${data.name}`,
                url: "/eventos",
                district: "Eventos",
              };
              await historyService.createHistory(history);
              this.$buefy.toast.open({
                duration: 5000,
                message: "Se ha registrado en éste evento exitosamente",
                position: "is-bottom",
                type: "is-success",
              });
            }
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: "is-bottom",
              type: "is-danger",
            });
          }
        },
      });
    },
  },
};
</script>
