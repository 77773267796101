import { nodeInstance } from "@/plugins/axios";

const historyService = {};

historyService.createHistory = obj => {
  return nodeInstance.post('/api/history', obj);
}

historyService.getHistoryByUser = () => {
  return nodeInstance.get('/api/history');
}

export default historyService;
